/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';

import api from '../../services/api';

import Article from './components/Article';
import Sidebar from './components/Sidebar';
import Search from '../../components/Search';

import * as styles from './styles';

const Articles: React.FC = () => {
  const history = useHistory();

  const { docId, categoryId } = useParams();
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSubCategory = async () => {
      if (!categoryId) {
        return <Redirect to="/" />;
      }

      const response = await api.get(`categories/${categoryId}`);

      const hasDocs = !!response.data.subcategories[0].docs[0];

      if (!docId && hasDocs) {
        history.push(
          `/categoria/${categoryId}/${response.data.subcategories[0].docs[0]._id}`,
        );
      }

      setCategory(response.data);
      setLoading(false);
    };

    getSubCategory();
  }, [categoryId]);

  return (
    <styles.Wrapper>
      <PageHeader
        onBack={() => history.push('/')}
        title={!loading ? category.name : ''}
        extra={[
          <div style={{ minWidth: '310px' }}>
            <Search />
          </div>,
        ]}
      />

      <styles.Container>
        <Sidebar />
        <styles.Content>{docId ? <Article /> : ''}</styles.Content>
      </styles.Container>
    </styles.Wrapper>
  );
};

export default Articles;
