import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  h1 {
    font-weight: 300;
  }
`;

export const Header = styled.div`
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    max-width: 1200px;
    margin: 0 auto;

    h1 {
      font-size: 4rem;
      color: #78b02d;
      margin: 0;
    }

    h2 {
      font-size: 2rem;
      margin-bottom: -30px;
    }
  }

  @media (max-width: 950px) {
    padding: 0px 20px;

    > div {
      grid-template-columns: 1fr;

      h1 {
        font-size: 3rem;
        color: #78b02d;
        margin: 0;
      }

      h2 {
        font-size: 1.5rem;
        margin-bottom: -20px;
      }
    }
  }
`;

export const SearchContent = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 950px) {
    width: 90%;
  }
`;

export const NeedHelp = styled.div`
  background: #fff;
  padding: 30px;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    h1 {
      font-size: 3rem;
      color: #78b02d;
      margin: 0;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: -20px;
    }
  }

  @media (max-width: 950px) {
    > div {
      grid-template-columns: 1fr;
    }
  }
`;
