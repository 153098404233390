import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Card, Button } from 'antd';
import { Link } from 'react-router-dom';

import { EditOutlined } from '@ant-design/icons';
import * as styles from './styles';

import categoriesIcons from './icons';

import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/auth';

const Categories: React.FC = () => {
  const { user } = useAuth();

  const [listCategories, setListCategories] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getListCategories() {
      const response = await api.get(`categories`);

      function isVisible(value: any) {
        if (user) {
          return value;
        }
        return value.visible === true;
      }

      const visibleCategories = response.data.filter(isVisible);
      setListCategories(
        visibleCategories.sort((a: any, b: any) => {
          if (a.order > b.order) {
            return 1;
          }
          return -1;
        }),
      );

      setLoading(false);
    }

    getListCategories();
  }, [user]);

  return (
    <styles.Wrapper>
      <styles.Container>
        <h1>Encontre sua dúvida por categoria aqui</h1>
        {user ? (
          <Link to="/info/Categorias">
            <Button
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size="small"
              style={{
                marginBottom: 20,
              }}
            >
              Editar
            </Button>
          </Link>
        ) : (
          ''
        )}
        <ul>
          <List
            grid={{
              gutter: 8,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 5,
              xxl: 5,
            }}
            loading={loading}
            dataSource={listCategories}
            renderItem={(category: any) => (
              <List.Item>
                <Link to={`/categoria/${category._id}`}>
                  <Card
                    hoverable
                    style={{ textAlign: 'center', borderRadius: '10px' }}
                    className="Teste"
                    loading={loading}
                    actions={[<>{category.numOfSubs} Tópicos</>]}
                  >
                    <div className="icon-category">
                      {categoriesIcons.map((icon: any) => {
                        return (
                          icon.name.toLowerCase() ===
                            category.name.toLowerCase() && (
                            <FontAwesomeIcon icon={icon.icon} />
                          )
                        );
                      })}
                    </div>
                    <h2>{category.name}</h2>
                  </Card>
                </Link>
              </List.Item>
            )}
          />
        </ul>
      </styles.Container>
    </styles.Wrapper>
  );
};

export default Categories;
