/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Modal, Collapse, Switch } from 'antd';
import 'antd/dist/antd.css';

import * as styles from './styles';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

const { Panel } = Collapse;
interface ModalComponentProps {
  isSubCategory: boolean;
  modalHandler: () => void;
  category: object[];
}

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const ModalComponent: React.FC<ModalComponentProps> = ({
  isSubCategory,
  modalHandler,
  category,
}) => {
  const { addToast } = useToast();

  // Modal

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [subVisible, setSubVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const showCategoryModal = () => {
    setCategoryVisible(true);
  };

  const showSubModal = () => {
    setSubVisible(true);
  };

  const handleCategoryCancel = () => {
    setCategoryVisible(false);
    modalHandler();
  };

  const handleSubCancel = () => {
    setSubVisible(false);
    modalHandler();
  };

  const onFinishCategory = (values: any) => {
    api
      .post('categories', values)
      .then(response =>
        addToast({
          type: 'success',
          title: 'Criado com sucesso',
        }),
      )
      .catch(err =>
        addToast({
          type: 'error',
          title: 'Erro ao criar',
          description:
            'Não foi possível criar, revise se já não existe um artigo com esse nome.',
        }),
      );
  };

  const onFinishSubCategory = (values: any) => {
    api
      .post('subcategories', values)
      .then(response =>
        addToast({
          type: 'success',
          title: 'Criado com sucesso',
        }),
      )
      .catch(err =>
        addToast({
          type: 'error',
          title: 'Erro ao criar',
          description:
            'Não foi possível criar, revise se já não existe um artigo com esse nome.',
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    addToast({
      type: 'error',
      title: `Failed: ${errorInfo}`,
    });
  };

  // Modal

  return (
    <styles.Container>
      {isSubCategory ? (
        <>
          <Button type="primary" onClick={showSubModal}>
            Criar Sub-Categoria
          </Button>
          <Modal
            onCancel={handleSubCancel}
            title="Criar Sub-Categoria"
            footer={null}
            visible={subVisible}
            confirmLoading={confirmLoading}
          >
            <Form
              {...layout}
              name="basic2"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishSubCategory}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item label="Categoria" name="category">
                <Select style={{ width: 200 }}>
                  {category
                    ? category
                        .sort((a: any, b: any) => {
                          if (a.order > b.order) {
                            return 1;
                          }
                          return -1;
                        })
                        .map((categoria: any) => (
                          <Option value={categoria._id}>
                            {categoria.name}
                          </Option>
                        ))
                    : 'loading'}
                </Select>
              </Form.Item>

              <Form.Item label="SubCategoria" name="name">
                <Input />
              </Form.Item>

              <Collapse>
                <Panel
                  header="Configurações de Link"
                  key="ChangePassword"
                  style={{ marginBottom: '20px' }}
                >
                  <Form.Item
                    label="Link"
                    name="isLink"
                    style={{
                      display: 'inline-block',
                      width: '33% ',
                    }}
                  >
                    <Switch checkedChildren="Sim" unCheckedChildren="Não" />
                  </Form.Item>
                  <Form.Item
                    label="URL"
                    name="url"
                    style={{
                      display: 'inline-block',
                      width: '66% ',
                    }}
                  >
                    <Input />
                  </Form.Item>
                </Panel>
              </Collapse>

              <Button type="primary" htmlType="submit">
                Criar SubCategoria
              </Button>
            </Form>
          </Modal>
        </>
      ) : (
        <>
          <Button type="primary" onClick={showCategoryModal}>
            Criar Categoria
          </Button>
          <Modal
            onCancel={handleCategoryCancel}
            title="Criar Categoria"
            footer={null}
            visible={categoryVisible}
            confirmLoading={confirmLoading}
          >
            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinishCategory}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item label="Categoria" name="name">
                <Input />
              </Form.Item>

              <Button type="primary" htmlType="submit">
                Criar Categoria
              </Button>
            </Form>
          </Modal>
        </>
      )}
    </styles.Container>
  );
};

export default ModalComponent;
