/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import { Editor } from 'react-draft-wysiwyg';
import {
  convertToRaw,
  convertFromHTML,
  ContentState,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import * as styles from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const FormEditor: React.FC = () => {
  const history: any = useHistory();
  const [form] = Form.useForm();

  const { addToast } = useToast();
  const { docId } = useParams();
  const [loading, setLoading] = useState(true);
  const [doc, setDoc] = useState();
  const [editorState, setEditorState] = useState();

  const onFinish = (values: any) => {
    values.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    api
      .put(`docs/${docId}`, values)
      .then(
        response =>
          addToast({
            type: 'success',
            title: 'Artigo atualizado com sucesso',
          }),
        history.go(-1),
      )
      .catch(err =>
        addToast({
          type: 'error',
          title: 'Erro ao criar',
          description: 'Não foi possível Atualizar.',
        }),
      );
  };

  const onFinishFailed = (errorInfo: any) => {
    addToast({
      type: 'error',
      title: `Failed: ${errorInfo}`,
    });
  };

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };

  const getDoc = async () => {
    const response: any = await api.get(`docs/${docId}`);
    setDoc(response.data);

    // setEditorState(response.data.content);
    setLoading(false);

    const html = response.data.content;
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(state));
  };

  const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
      const formData: any | null = new FormData();
      const imagefile: any | null = document.querySelector('#file');

      formData.append('image', imagefile.files[0]);
      api
        .post('files', formData, {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(response => resolve(response));
    });
  };

  useEffect(() => {
    getDoc();
    console.log(doc);
  }, [setDoc]);

  return (
    <styles.FormContainer>
      <p>Criar Artigo</p>
      {!loading ? (
        <Form
          form={form}
          {...layout}
          name="basic"
          initialValues={loading ? { name: 'bruno' } : doc}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Titulo" name="name">
            <Input style={{ width: 200 }} />
          </Form.Item>
          <styles.EditorContainer>
            <Editor
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: {
                  uploadCallback: uploadImageCallBack,
                  alt: { present: true, mandatory: true },
                },
              }}
            />
          </styles.EditorContainer>
          <Form.Item {...tailLayout} style={{ marginBottom: `0px` }}>
            <Button type="primary" htmlType="submit">
              Atualizar
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <p>loading</p>
      )}
    </styles.FormContainer>
  );
};

export default FormEditor;
