/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import * as styles from './styles';

import { useAuth } from '../../hooks/auth';
import logoImg from '../../assets/logo.svg';

const Navbar: React.FC = () => {
  const history = useHistory();
  const { signOut, user } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    history.push('/');
    signOut();
  };

  return (
    <styles.Container>
      <styles.Content>
        <nav>
          <Link to="/">
            <img src={logoImg} alt="Equipe Remota" />
          </Link>
        </nav>

        <aside>
          {user && location.pathname !== '/criarArtigo' ? (
            <Link
              to={{
                pathname: '/criarArtigo',
                state: { prevPath: location.pathname },
              }}
            >
              <Button
                style={{
                  background: '#455862',
                  color: '#fff',
                  border: 0,
                }}
              >
                Criar Artigo
              </Button>
            </Link>
          ) : (
            <Link to="/ticket">
              <Button
                style={{
                  background: '#455862',
                  color: '#fff',
                  border: 0,
                }}
              >
                Abrir um novo Ticket
              </Button>
            </Link>
          )}

          <styles.Profile>
            {user ? (
              <>
                <b>{user.name}</b>

                <Button type="text" onClick={handleLogout}>
                  Sair
                </Button>
              </>
            ) : (
              ''
            )}
          </styles.Profile>
        </aside>
      </styles.Content>
    </styles.Container>
  );
};

export default Navbar;
