import React, { useEffect, useState } from 'react';
import { List, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

import api from '../../../../services/api';
import * as styles from './styles';

const ArticlesMostViewed: React.FC = () => {
  const [listArticles, setListArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getListCategories() {
      const response = await api.get(`docs/topviews`);

      const { data } = response;

      setListArticles(data.slice(0, 9));

      setLoading(false);
    }

    getListCategories();
  }, []);

  return (
    <styles.Wrapper>
      <styles.Container>
        <h1>Artigos mais lidos</h1>
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 3,
          }}
          loading={loading}
          dataSource={listArticles}
          renderItem={(article: any) => (
            <List.Item>
              <Link to={`/categoria/${article.category}/${article._id}`}>
                <Button
                  size="large"
                  type="text"
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <RightOutlined /> {article.name}
                </Button>
              </Link>
            </List.Item>
          )}
        />
      </styles.Container>
    </styles.Wrapper>
  );
};

export default ArticlesMostViewed;
