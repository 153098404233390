import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import 'antd/dist/antd.css';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ModalComponent from './components/Modal';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import * as styles from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const FormEditor: React.FC = () => {
  const [form] = Form.useForm();

  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState();
  const [subCategories, setSubCategories] = useState();
  const [editorState, setEditorState] = useState();

  const [categoryValue, setCategoryValue] = useState('');

  const handleModal = useCallback(async () => {
    getCategory();

    if (categoryValue !== '') {
      const response = await api.get(`subcategories/category/${categoryValue}`);
      setSubCategories(response.data);
    }
  }, [categoryValue]);

  useEffect(() => {
    getCategory();
  }, [subCategories]);

  const getCategory = async () => {
    const response = await api.get('categories');
    setCategory(response.data);

    setLoading(true);
  };

  const getSubCategory = async (value: string) => {
    const response = await api.get(`subcategories/category/${value}`);
    setSubCategories(response.data);
    setCategoryValue(value);

    form.setFieldsValue({
      subcategory: '',
    });
  };

  const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
      const formData: any | null = new FormData();
      const imagefile: any | null = document.querySelector('#file');

      formData.append('image', imagefile.files[0]);
      api
        .post('files', formData, {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(response => resolve(response));
    });
  };

  async function onFinish(values: any) {
    values.content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    await api
      .post('docs', values)
      .then(response =>
        addToast({
          type: 'success',
          title: 'Criado com sucesso',
        }),
      )
      .catch(err =>
        addToast({
          type: 'error',
          title: 'Erro ao criar',
          description:
            'Não foi possível criar, revise se já não existe um artigo com esse nome.',
        }),
      );
  }

  const onFinishFailed = (errorInfo: any) => {
    addToast({
      type: 'error',
      title: `Failed: ${errorInfo}`,
    });
  };

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };

  console.log(category, subCategories);

  return (
    <styles.FormContainer>
      <p>Criar Artigo</p>
      <Form
        form={form}
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Categoria" name="selectCategory">
          <Select onChange={getSubCategory} style={{ width: 200 }}>
            {loading
              ? category
                  .sort((a: any, b: any) => {
                    if (a.order > b.order) {
                      return 1;
                    }
                    return -1;
                  })
                  .map((categoria: any) => (
                    <Option value={categoria._id}>{categoria.name}</Option>
                  ))
              : 'loading'}
          </Select>{' '}
        </Form.Item>
        <ModalComponent
          category={category}
          isSubCategory={false}
          modalHandler={handleModal}
        />

        <Form.Item label="Subcategoria" name="category">
          <Select style={{ width: 200 }}>
            {subCategories
              ? subCategories
                  .sort((a: any, b: any) => {
                    if (a.order > b.order) {
                      return 1;
                    }
                    return -1;
                  })
                  .map((categoria: any) => (
                    <Option value={categoria._id}>{categoria.name}</Option>
                  ))
              : 'loading'}
          </Select>
        </Form.Item>
        <ModalComponent
          category={category}
          isSubCategory
          modalHandler={handleModal}
        />

        <Form.Item label="Titulo" name="name">
          <Input style={{ width: 200 }} />
        </Form.Item>
        <styles.EditorContainer>
          <Editor
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                uploadCallback: uploadImageCallBack,
                alt: { present: true, mandatory: true },
              },
            }}
          />
        </styles.EditorContainer>
        <Form.Item {...tailLayout} style={{ marginBottom: `0px` }}>
          <Button type="primary" htmlType="submit">
            Criar
          </Button>
        </Form.Item>
      </Form>
    </styles.FormContainer>
  );
};

export default FormEditor;
