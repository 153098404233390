import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div``;

export const Content = styled.div`
  padding: 24px;
`;
