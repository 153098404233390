import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 40px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column wrap;
  align-items: center;
  background-color: #f1f4f9;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1))
    no-repeat;
`;

export const EditorContainer = styled.div`
  overflow: auto;
  max-width: 100%;
  max-height: 90%;

  .rdw-editor-main {
    border: 4px solid rgb(241, 241, 241);
  }
`;

export const FormContainer = styled.div`
  padding: 30px;
  margin: 30px;

  background-color: #fff;
  background-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.01));
  border: 1px;
  border-radius: 10px;
  width: 60%;
  box-shadow: 0px 0px 20px gray;

  /* form {
    position: center;
    padding: 20px;
    flex-flow: column wrap;
    background: blue;
    width: 100%;
  } */

  p {
    display: flex;
    font-size: 25px;
    justify-content: center;
    color: #1c1c1c;
  }

  button {
    margin: 10px;
    display: flex;
    position: relative;
  }
`;
