import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 950px) {
    padding: 0 20px;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  .icon-category {
    color: #fff;
    margin: 0px auto;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    background: #455862;
    border-radius: 100%;
  }

  .ant-card-body {
    padding: 24px 0px;
  }

  h2 {
    margin: 20px 0 0 0;
  }
`;
