import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #fff;
  @media (max-width: 950px) {
    padding: 0 20px;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
`;
