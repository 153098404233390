import React, { useState } from 'react';

// import { Container } from './styles';
import { Select, Tag } from 'antd';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

const Search: React.FC = () => {
  const history = useHistory();

  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelect = (path: any) => {
    history.push(`/${path}`);
  };
  const searchByname = async (value: any) => {
    if (value.length <= 4) {
      return;
    }
    setLoading(true);
    const response = await api.get(`/docs/search?nome=${value}`);

    setDocs(response.data);
    setLoading(false);
  };

  return (
    <>
      <Select
        showSearch
        showArrow={false}
        style={{ width: '100%' }}
        placeholder="Pesquisar no site"
        loading={loading}
        size="large"
        optionFilterProp="children"
        onSearch={searchByname}
        onSelect={handleSelect}
        notFoundContent={null}
      >
        {docs.map((doc: any) => {
          return (
            <Select.Option value={`categoria/${doc.category._id}/${doc._id}`}>
              <Tag color="purple">{doc.category.name}</Tag> {doc.name}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default Search;
