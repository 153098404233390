import React from 'react';
import * as styles from './styles';

import FormEditor from './formEditor';

const CreateArticle: React.FC = () => {
  return (
    <>
      <styles.Container>
        <FormEditor />
      </styles.Container>
    </>
  );
};

export default CreateArticle;
