import React from 'react';
import Navbar from '../../../components/Navbar';

import * as styles from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <>
      <Navbar />
      <styles.Container>{children}</styles.Container>
    </>
  );
};

export default DefaultLayout;
