import React from 'react';

import { Button } from 'antd';
import { Link } from 'react-router-dom';
import * as styles from './styles';

import Categories from './components/Categories';
import ArticlesMostViewed from './components/ArticlesMostViewed';
import Search from '../../components/Search';

const Dashboard: React.FC = () => {
  return (
    <styles.Container>
      <styles.Header>
        <div>
          <div>
            <h2>Bem vindo ao </h2>
            <h1>Central de Ajuda</h1>
          </div>
          <Search />
        </div>
      </styles.Header>

      <ArticlesMostViewed />

      <Categories />

      <styles.NeedHelp>
        <div>
          <div>
            <h2>Não encontrou sua</h2>
            <h1>Dúvida?</h1>
          </div>
          <Link to="/ticket">
            <Button
              style={{
                background: '#455862',
                color: '#fff',
                border: 0,
                width: '280px',
                height: '60px',
                fontSize: '1.25rem',
                fontWeight: 500,
              }}
            >
              Abrir um novo Ticket
            </Button>
          </Link>
        </div>
      </styles.NeedHelp>
    </styles.Container>
  );
};

export default Dashboard;
