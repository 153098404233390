import React from 'react';
import * as styles from './styles';

import FormEditor from './formEditor';

const EditArticle: React.FC = () => {
  return (
    <styles.Container>
      <FormEditor />
    </styles.Container>
  );
};

export default EditArticle;
