import React from 'react';

import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
// import { Container } from './styles';

const PageNotFound: React.FC = () => {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, a página que você visitou não existe."
      extra={
        <Button
          type="primary"
          style={{ borderColor: '#78b02d', backgroundColor: '#78b02d' }}
          onClick={() => {
            history.push('/');
          }}
        >
          Voltar para Página Inicial
        </Button>
      }
    />
  );
};

export default PageNotFound;
