import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.centraldeajuda.equiperemota.com.br/',
});

const token = localStorage.getItem('@CentralAjuda:token');

api.defaults.headers.common.Authorization = `Bearer ${token}`;

export default api;
