import * as icons from '@fortawesome/free-solid-svg-icons';

const categoriesIcons = [
  {
    name: 'undefined',
    icon: icons.faCircle,
  },
  {
    name: 'Painel',
    icon: icons.faColumns,
  },
  {
    name: 'Mensagens',
    icon: icons.faEnvelope,
  },
  {
    name: 'Calendário',
    icon: icons.faCalendar,
  },
  {
    name: 'Clientes',
    icon: icons.faUsers,
  },
  {
    name: 'Propostas Comerciais',
    icon: icons.faHandshake,
  },
  {
    name: 'Vendas',
    icon: icons.faHandHoldingUsd,
  },
  {
    name: 'PLM  Engenharia',
    icon: icons.faBox,
  },
  {
    name: 'Compra',
    icon: icons.faCartArrowDown,
  },
  {
    name: 'Logística',
    icon: icons.faTruck,
  },
  {
    name: 'Mrp',
    icon: icons.faDolly,
  },
  {
    name: 'Reparos',
    icon: icons.faWrench,
  },
  {
    name: 'Faturamento',
    icon: icons.faWallet,
  },
  {
    name: 'Projeto',
    icon: icons.faProjectDiagram,
  },
  {
    name: 'Pesquisas',
    icon: icons.faPoll,
  },
  {
    name: 'Rh',
    icon: icons.faAddressBook,
  },
  {
    name: 'Recrutamento',
    icon: icons.faUserCheck,
  },
  {
    name: 'Despesas',
    icon: icons.faMailBulk,
  },
  {
    name: 'Manutenção',
    icon: icons.faUsersCog,
  },
  {
    name: 'Treinamentos',
    icon: icons.faChalkboardTeacher,
  },
  {
    name: 'Qualidade',
    icon: icons.faClipboardCheck,
  },
  {
    name: 'Aplicativos',
    icon: icons.faWindowRestore,
  },
  {
    name: 'Configurações',
    icon: icons.faCog,
  },
];

export default categoriesIcons;
