import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: #f1f4f9;
    color: #333;
    -webkit-font-smoothing: antiased;
    }

    nav div {
      background: #f9f9f9;
    }

  body, input, button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  .card{
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 10px;

    p {
      color: black;
    }
  }

  @media screen and (min-width: 768px) {

    nav {
      flex-direction: row;
      justify-content: space-around;
    }

    nav a b div img {
      flex-direction: row;
      width: 70%;

    }

    body {
      height: 600px;
      height: 60vh;
      justify-content: center;
    }

  }
`;
