import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import api from '../../../../services/api';
import PopupExclude from '../../../../components/PopupExclude';

import * as styles from './styles';
import { useAuth } from '../../../../hooks/auth';

const Sidebar: React.FC = () => {
  const { user } = useAuth();

  const [subCategories, setSubCategories] = useState([]);

  const { categoryId } = useParams();

  useEffect(() => {
    const getSubCategory = async () => {
      if (!categoryId) {
        return <Redirect to="/" />;
      }
      const response = await api.get(`subcategories/category/${categoryId}`);

      function isVisible(value: any) {
        if (user) {
          return value;
        }
        return value.visible === true;
      }

      const visibleSubcategories = response.data.filter(isVisible);

      setSubCategories(
        visibleSubcategories.sort((a: any, b: any) => {
          if (a.order > b.order) {
            return 1;
          }
          return -1;
        }),
      );
    };

    getSubCategory();
  }, [categoryId, user]);

  return (
    <styles.Container>
      {user ? <PopupExclude route={`categories/${categoryId}`} /> : ''}
      <Menu mode="inline">
        {subCategories.map((subCategory: any) => (
          <>
            {subCategory.isLink === true ? (
              <Menu.Item>
                <a href={subCategory.url}>{subCategory.name}</a>
              </Menu.Item>
            ) : (
              <Menu.SubMenu
                key={subCategory._id}
                title={<>{subCategory.name}</>}
              >
                {user ? (
                  <PopupExclude
                    route={`subcategories/${subCategory._id}`}
                    subId={subCategory._id}
                  />
                ) : (
                  ''
                )}
                {subCategory.docs
                  .sort((a: any, b: any) => {
                    if (a.order > b.order) {
                      return 1;
                    }
                    return -1;
                  })
                  .map((article: any) => (
                    <Menu.Item key={article._id}>
                      <Link to={`/categoria/${categoryId}/${article._id}`}>
                        {article.name}
                      </Link>
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            )}
          </>
        ))}
      </Menu>
    </styles.Container>
  );
};

export default Sidebar;
