import React, { useEffect, useState } from 'react';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import { Card, Form, Input, Button, Select, notification } from 'antd';
import * as styles from './styles';

import api from '../../services/api';

interface CategoryInterface {
  _id: string;
  name: string;
}

const SendingRequests: React.FC = () => {
  const [listCategories, setListCategories] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    async function loadCategories() {
      const response = await api.get(`categories`);
      const { data } = response;

      setListCategories(data);
    }
    loadCategories();
  }, []);

  const onFinish = (values: any) => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    api
      .post(`tickets`, {
        title: `${values.department} - ${values.subject}`,
        content,
        email: values.email,
        matter: values.department,
      })
      .then(response =>
        notification.success({
          message: 'Enviado com sucesso',
          description: 'Em breve nosso suporte estará entrando em contato.',
        }),
      )
      .catch(err =>
        notification.error({
          message: 'Erro ao enviar',
          description:
            'Não foi possível enviar, revise os dados ou tente novamente mais tarde.',
        }),
      );
  };

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };

  const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.imgur.com/3/image');
      xhr.setRequestHeader('Authorization', 'Client-ID 2750f87e340941f');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = JSON.parse(xhr.responseText);

        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);

        reject(error);
      });
    });
  };

  return (
    <styles.Container>
      <h1>Envie uma solicitação</h1>

      <Card>
        <Form layout="vertical" name="basic" onFinish={onFinish}>
          <Form.Item name="department" label="Selecione um departamento">
            <Select showSearch placeholder="Selecione assunto">
              <Select.Option key="Sugestão" value="Sugestão">
                Sugestão
              </Select.Option>
              {listCategories.map((category: CategoryInterface) => (
                <Select.Option key={category._id} value={category.name}>
                  {category.name}
                </Select.Option>
              ))}
              <Select.Option key="Outros" value="Outros">
                Outros
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="email" label="Seu Email">
            <Input />
          </Form.Item>

          <Form.Item name="subject" label="Assunto">
            <Input />
          </Form.Item>

          <Form.Item>
            <styles.EditorContent>
              <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  image: {
                    uploadCallback: uploadImageCallBack,
                    alt: { present: true, mandatory: true },
                  },
                }}
              />
            </styles.EditorContent>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </styles.Container>
  );
};

export default SendingRequests;
