import React, { useEffect, useState } from 'react';

import ReactHtmlParser from 'react-html-parser';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Card, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import * as styles from './styles';
import { useAuth } from '../../../../hooks/auth';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface ArticleProps {}

const Article: React.FC<ArticleProps> = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const history: any = useHistory();

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const { docId } = useParams();
  const [content, setContent] = useState();

  useEffect(() => {
    const getContent = async () => {
      const response = await api.get(`docs/${docId}`);

      setContent(
        (!response.data.visible && user) || response.data.visible
          ? response.data
          : {},
      );
      setLoading(false);
    };

    getContent();
  }, [docId, user]);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    api
      .delete(`docs/${docId}`)
      .then(
        response =>
          addToast({
            type: 'success',
            title: 'Deletado com sucesso',
          }),
        history.go(-1),
      )
      .catch(err =>
        addToast({
          type: 'error',
          title: 'Erro ao criar',
          description: 'Não foi possível Deletar.',
        }),
      );
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <styles.Container>
      <Card style={{ borderRadius: '10px' }} loading={loading}>
        {content ? ReactHtmlParser(content.content) : ''}
        {user ? (
          <>
            <Link to={`/editarArtigo/${docId}`}>
              <Button type="primary" shape="round" icon={<EditOutlined />}>
                Editar
              </Button>
            </Link>
            <Popconfirm
              title="Deseja mesmo excluir o Artigo?"
              okText="Sim"
              cancelText="Não"
              visible={visible}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <Button
                danger
                type="primary"
                shape="round"
                icon={<DeleteOutlined />}
                onClick={showPopconfirm}
                style={{ marginLeft: '10px' }}
              >
                Excluir
              </Button>
            </Popconfirm>
          </>
        ) : (
          ''
        )}
      </Card>
    </styles.Container>
  );
};

export default Article;
