import React from 'react';
import { useParams } from 'react-router-dom';
import Article from '../../components/Info/Article';
import Category from '../../components/Info/Category';
import SubCategory from '../../components/Info/SubCategory';

import * as styles from './styles';

const Articles: React.FC = () => {
  const { type } = useParams();

  return (
    <styles.Container>
      <styles.Content>
        {type === 'Artigos' ? <Article /> : ''}
        {type === 'SubCategorias' ? <SubCategory /> : ''}
        {type === 'Categorias' ? <Category /> : ''}
      </styles.Content>
    </styles.Container>
  );
};

export default Articles;
