/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Space,
  Tooltip,
  Button,
  Switch,
} from 'antd';
import {
  EditOutlined,
  CheckOutlined,
  UndoOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface Item {
  name: string;
  order: string;
  visible: boolean;
  _id: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    dataIndex === 'visible' ? (
      <Switch
        checkedChildren="Visível"
        unCheckedChildren="Invisível"
        defaultChecked={record.visible}
      />
    ) : (
      <Input />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = () => {
  const { addToast } = useToast();

  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState('');

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getDocs = async () => {
      const dados = await api.get(`categories/`);
      setData(
        dados.data.sort((a: any, b: any) => {
          if (a.order > b.order) {
            return 1;
          }
          return -1;
        }),
      );
      setLoading(false);
    };
    getDocs();
    setRefresh(false);
  }, [refresh]);

  const isEditing = (record: Item) => record.order === editingKey;

  const edit = (record: Item) => {
    form.setFieldsValue({ name: '', order: '', ...record });
    setEditingKey(record.order);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleDelete = async (id: string, index: number) => {
    try {
      await api.delete(`categories/${id}`);
      const dataDelete = [...data];
      dataDelete.splice(index, 1);
      setData([...dataDelete]);
      addToast({
        type: 'success',
        title: 'Deletado com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar',
        description: `Erro: ${error}`,
      });
    }
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex(item => key === item.order);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        await api.put(`categories/${item._id}`, row);
        setData(newData);
        setRefresh(true);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setRefresh(true);
        setEditingKey('');
      }
      addToast({
        type: 'success',
        title: 'Atualizado com sucesso',
      });
    } catch (errInfo) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar',
        description: 'Não foi possível atualizar, revise as informações.',
      });
    }
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',

      editable: true,
    },
    {
      title: 'Ordem',
      dataIndex: 'order',
      width: '0',
      editable: true,
    },
    {
      title: 'Visibilidade',
      dataIndex: 'visible',
      editable: true,
      render: (visible: any) => `${visible === true ? 'Visível' : 'Invisível'}`,
    },
    {
      title: 'Operações',
      dataIndex: 'operation',
      render: (_: any, record: Item, rowIndex: number) => {
        const editable = isEditing(record);
        return (
          <Space size="middle">
            {editable ? (
              <>
                <Popconfirm
                  title="Efetuar alterações?"
                  onConfirm={() => save(record.order)}
                >
                  <Tooltip title="Salvar">
                    <Button shape="circle" icon={<CheckOutlined />} />
                  </Tooltip>
                </Popconfirm>
                <Tooltip title="Voltar">
                  <Button
                    shape="circle"
                    onClick={cancel}
                    icon={<UndoOutlined />}
                  />
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Editar">
                <Button
                  shape="circle"
                  disabled={editingKey !== ''}
                  onClick={() => edit(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            <Popconfirm
              title="Deseja mesmo deletar a Categoria?"
              onConfirm={() => handleDelete(record._id, rowIndex)}
            >
              <Tooltip title="Deletar">
                <Button
                  type="primary"
                  shape="circle"
                  disabled={editingKey !== ''}
                  icon={<DeleteOutlined />}
                  danger
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        title={() => 'Categorias'}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        loading={loading}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default EditableTable;
