import { Button } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import * as styles from './styles';

interface PopupExcludeProps {
  route: string;
  subId?: string;
}

const PopupExclude: React.FC<PopupExcludeProps> = ({ route, subId }) => {
  const { categoryId } = useParams();

  return (
    <styles.Container>
      {subId ? (
        <>
          <Link to={`/info/Artigos/${subId}`}>
            <Button
              style={{ marginBottom: '10px' }}
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size="small"
            >
              Editar Ordem de Artigos
            </Button>
          </Link>
        </>
      ) : (
        <>
          <Link to={`/info/SubCategorias/${categoryId}`}>
            <Button
              style={{ marginBottom: '10px' }}
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size="small"
            >
              Editar Ordem de Sub-Categorias
            </Button>
          </Link>
        </>
      )}
    </styles.Container>
  );
};

export default PopupExclude;
