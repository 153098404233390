import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const EditorContent = styled.div`
  width: 100%;
  height: 250px;
  margin-bottom: 80px;
  display: flex;

  .rdw-editor-main {
    border: 2px solid rgb(241, 241, 241);
  }
`;
