import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.08);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0px;
  margin: 0 auto;
  max-width: 1200px;
  position: sticky;
  top: 0;

  button {
    display: block;
    margin-top: 2px;
    margin-left: 20px;
    font-size: 16px;
    color: #030202;
  }
  button:hover {
    color: #78b02d;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  aside {
    display: flex;
  }

  img {
    width: 250px;
  }

  a {
    display: block;
    margin-top: 2px;
    font-size: 16px;
    color: #030202;
  }
  a:hover {
    color: #78b02d;
  }

  @media (max-width: 650px) {
    padding: 8px 0;
    flex-direction: column;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;

  a {
    color: #030202;
  }
  b {
    color: #030202;
  }
`;
