import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const Container = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: baseline;
  gap: 20px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  width: 100%;
`;
