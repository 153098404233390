import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Dashboard from '../pages/Dashboard';

import SignIn from '../pages/SignIn';
import Articles from '../pages/Articles';
import CreateArticle from '../pages/CreateArticle';
import EditArticle from '../pages/EditArticle';
import EditOrder from '../pages/EditOrder';
import SendingRequests from '../pages/SendingRequests';
import PageNotFound from '../pages/_PageNotFound';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/login" isLogin component={SignIn} />
    <Route path="/categoria/:categoryId/:docId?" component={Articles} />

    <Route path="/criarArtigo" exact isPrivate component={CreateArticle} />
    <Route
      path="/editarArtigo/:docId"
      exact
      isPrivate
      component={EditArticle}
    />
    <Route path="/info/:type/:infoId?" isPrivate component={EditOrder} />
    <Route path="/ticket" component={SendingRequests} />
    <Route path="/" component={PageNotFound} />
  </Switch>
);

export default Routes;
